import { Controller } from "@hotwired/stimulus"
import EasyMDE from 'easymde/dist/easymde.min'

export default class extends Controller {
  connect() {
    //$('.calendar.datetimepicker').calendar()
    $("#docs").find(".reference-text").each((index, element) => {
      //console.log(element)
      // let mde;
      // mde = new EasyMDE({element: element});
    });
  }
}
